<template>
    <div id="defaultId1" class="u4XnOv6 pb20">
        <card id="g84750" class="uj28m1R mb10" :isShowLabel="true" label="Industry Stats" v-if="tableData?.length > 0">
            <el-table id="idW4vMUP" class="Industry uH1RPZr" :data="tableData" empty-text="No Data">
                <el-table-column id="idKo8goB" prop="name" :width="firstWidth" label
                    style="border-bottom: 1px solid #cccccc">
                </el-table-column>
                <el-table-column id="idcOOd6d" prop="2023" label="2023" align="right">
                </el-table-column>
                <el-table-column id="idLHAngG" prop="2022" label="2022" align="right">
                </el-table-column>
                <el-table-column id="idcOOd6d" prop="2021" label="2021" align="right">
                </el-table-column>
                <el-table-column id="idLHAngG" prop="2020" label="2020" align="right">
                </el-table-column>
                <el-table-column id="idLHAngG" prop="2019" label="2019" align="right">
                </el-table-column>

            </el-table>
        </card>
        <card id="c4eddc" class="uj28m1R mb10" :isShowLabel="true" label="Observations" v-if="ObserData?.length > 0">
            <el-table id="ced530" class="Industry uH1RPZr" :data="ObserData" empty-text="No Data">
                <el-table-column id="c96d84" class=" uegVMtq  " prop="name" label :width="firstWidth"
                    style="border-bottom:1px solid #ccc">
                </el-table-column>
                <el-table-column id="c3b9a1" class=" u8GFkjM" prop="Average" label="Average Value" align="right">
                </el-table-column>
                <el-table-column id="cd354e" class=" uPdzCgJ" prop="Media" label="Median Value" align="right">
                </el-table-column>
                <el-table-column id="c026d4" class=" ullp6Ih" prop="Max10" label="Maximum Value 10%" align="right">
                </el-table-column>
                <el-table-column id="c57533" class=" ui8Zk2n" prop="Min10" label="Minimum Value 10%" align="right">
                </el-table-column>
                <el-table-column id="cbf47c" class=" ucuQ48p" prop="Target" label="Target Company" align="right">
                </el-table-column>
            </el-table>
        </card>
        <card :isShowLabel="true" label="Performance" class="ability" v-if="abilityData?.length > 0">
            <el-table id="idW4vMUP" class="uH1RPZr table_height_30 table_font_size_14 table_contentHeight_40 table_width_max" :data="abilityData" :span-method="objectSpanMethod"
                empty-text="No Data">
                <el-table-column prop="name" label="" :width="thirdWidth1" class-name="black-backgroundone"></el-table-column>
                <el-table-column prop="proportion" label="" :width="thirdWidth2" class-name="black-background">
                </el-table-column>
                <el-table-column prop="Average" label="Average Value" align="right" class-name="white-background">
                </el-table-column>
                <el-table-column prop="Media" label="Median Value" align="right">
                </el-table-column> <el-table-column prop="Max10" label="Maximum Value 10%" align="right">
                </el-table-column> <el-table-column prop="Min10" label="Minimum Value 10%" align="right">
                </el-table-column> <el-table-column prop="Target" label="Target Company" align="right">
                </el-table-column>
            </el-table>
        </card>
        <ReLogin :reLoginPop="reLoginVis"></ReLogin>
    </div>
    <!--srcviewscompanydetailsPerformanceIndustryNormsvuehtmlEnd-->

</template>
<script>
// interfaceCode
//importStart

import {
    getCompanyIndustry,
    getPerformanceSummary,
    getIndustryResearchComparison,
    getObservationProject,
    getAbilityPerformance
} from "@/api/companyApi";
import card from "../../../../components/gui_card/gui_card.vue";
import ReLogin from "../../../../components/ReLogin.vue";
//importEnd

export default {
    name: "",
    props: {
        // srcviewscompanydetailsPerformanceIndustryNormsvuePropsStart

        // srcviewscompanydetailsPerformanceIndustryNormsvuePropsEnd
    },
    created() {

        const screenWidth = window.innerWidth;
        if(screenWidth < 1650){
            this.firstWidth = (540 /1.5) + 'px'
            this.thirdWidth1 = (200 /1.2) + 'px'
            this.thirdWidth2 = (390 /1.5) + 'px'
        }else{
            this.thirdWidth1 = 200
            this.thirdWidth2 = 390
            this.firstWidth = 540
        }
       
    },
    mounted() {
        this.getPerformanceSummaryList()

    },

    components: {
        card,
        ReLogin,
    },

    data() {
        return {
            reLoginVis: false,
            // insertData
            // srcviewscompanydetailsPerformanceIndustryNormsvueDataStart

            // tableData
            tableData: [],
            ObserData: [],
            firstWidth: null,
            thirdWidth1: null,
            thirdWidth2: null,
            abilityData: [{
                name: 'Profitability Ratio',
                proportion: "Return on Equity (%)",
                Average: '',
                Median: '',
                Maximum: '',
                Minimum: '',
                Target: '',
            }, {
                name: 'Profitability Ratio',
                proportion: "Return on Total Assets(%)",
                Average: '',
                Median: '',
                Maximum: '',
                Minimum: '',
                Target: '',
            }, {
                name: 'Profitability Ratio',
                proportion: "Gross Profit Margin(%)",
                Average: '',
                Median: '',
                Maximum: '',
                Minimum: '',
                Target: '',
            }, {
                name: 'Operating Ratio',
                proportion: 'Accounts Receivable',
                Average: '',
                Median: '',
                Maximum: '',
                Minimum: '',
                Target: '',
            }, {
                name: 'Operating Ratio',
                proportion: 'Current Asset Turnover',
                Average: '',
                Median: '',
                Maximum: '',
                Minimum: '',
                Target: '',
            }, {
                name: 'Operating Ratio',
                proportion: 'Total Asset Turnover Rate',
                Average: '',
                Median: '',
                Maximum: '',
                Minimum: '',
                Target: '',
            }, {
                name: 'Structural Ratio',
                proportion: 'Current Ratio',
                Average: '',
                Median: '',
                Maximum: '',
                Minimum: '',
                Target: '',
            }, {
                name: 'Structural Ratio',
                proportion: 'Quick Ratio',
                Average: '',
                Median: '',
                Maximum: '',
                Minimum: '',
                Target: '',
            }, {
                name: 'Structural Ratio',
                proportion: 'Asset Liability Ratio',
                Average: '',
                Median: '',
                Maximum: '',
                Minimum: '',
                Target: '',
            }, {
                name: 'Talent Ratio',
                proportion: 'Revenue growth rate',
                Average: '',
                Median: '',
                Maximum: '',
                Minimum: '',
                Target: '',
            }, {
                name: 'Talent Ratio',
                proportion: 'Operating Profit Growth Rate',
                Average: '',
                Median: '',
                Maximum: '',
                Minimum: '',
                Target: '',
            }, {
                name: 'Talent Ratio',
                proportion: 'Total Asset Growth Rate',
                Average: '',
                Median: '',
                Maximum: '',
                Minimum: '',
                Target: '',
            }]
            // srcviewscompanydetailsPerformanceIndustryNormsvueDataEnd

        }
    },
    methods: {
        getPerformanceSummaryList() {
            getPerformanceSummary({ id3a: this.$route.query.id3a }).then(res => {
                if (res.code == 200) {
                    let manManagementSummary = [
                        {
                            name: "Revenue",
                            id: "Revenue",
                            num: res.data.revenue,
                        },
                        {
                            name: "Profit",
                            id: "Profit",
                            num: res.data.profit,
                        },
                        {
                            name: "Growth",
                            id: "Growth",
                            num: res.data.growth,
                        },
                        {
                            name: "Cash Flow Status",
                            id: "Cash Flow Status",
                            num: res.data.liquidity,
                        },
                        {
                            name: "Financial Structure",
                            id: "Financial Structure",
                            num: res.data.financialStructurt,
                        },
                        {
                            name: "Credit Opinion",
                            id: "Credit Opinion",
                            num: res.data.creditOpinion,
                        },
                        {
                            name: "Risk Indicator (RI)",
                            id: "Risk Indicator (RI)",
                            num: res.data.riskIndicatorLower,
                        },
                        {
                            name: "Solvency index(SI)",
                            id: "Solvency index(SI)",
                            num: res.data.solvencyindexSI,
                        },
                    ]

                    this.$emit('UpdatamarkTotalList', manManagementSummary)
                } else {
                    this.$emit('UpdatamarkTotalList', [])
                }
            }).catch(err => {
                this.reLoginVis = String(err).includes('403')
            })
            getCompanyIndustry({
                id3a: this.$route.query.id3a,
            }).then(res => {
                if (res.code == 200) {
                    getIndustryResearchComparison({
                        isicCode: res.data.isicCode,
                        countryCode: this.$route.query.companyCountry,
                    }).then(res2 => {
                        if (res2.code == 200) {
                            this.tableData = res2.data
                        } else {
                            this.tableData = []
                        }
                    })
                    getObservationProject({
                        isicCode: res.data.isicCode,
                        countryCode: this.$route.query.companyCountry,
                        id3a: this.$route.query.id3a
                    }).then(res3 => {
                        if (res3.code == 200) {

                            this.ObserData = res3.data

                        }
                    })
                    getAbilityPerformance({
                        isicCode: res.data.isicCode,
                        countryCode: this.$route.query.companyCountry,
                        id3a: this.$route.query.id3a
                    }).then(res4 => {
                        if (res4.code == 200) {

                            this.abilityData = res4.data

                        } else {
                            this.abilityData = []
                        }
                    })
                }

            })

        },

        default() {

        },
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            // 只处理第一列
            if (columnIndex === 0) {
                // 获取当前行的 name 值
                const currentName = row.name.replace(/\s+/g, '');
                // 获取上一行的 name 值
                const previousName = rowIndex > 0 ? this.abilityData[rowIndex - 1].name.replace(/\s+/g, '') : null;

                // 如果当前行的 name 和上一行的 name 相同
                if (currentName === previousName) {
                    return {
                        rowspan: 0, // 当前单元格不显示
                        colspan: 0   // 当前单元格不显示
                    };
                } else {
                    // 计算需要合并的行数
                    let rowspan = 1;
                    for (let i = rowIndex + 1; i < this.abilityData.length; i++) {
                        if (this.abilityData[i].name.replace(/\s+/g, '') === currentName) {
                            rowspan++;
                        } else {
                            break; // 一旦遇到不同的 name，就停止
                        }
                    }
                    return {
                        rowspan: rowspan, // 合并的行数
                        colspan: 1        // 只占用一列
                    };
                }
            }
        }
        // srcviewscompanydetailsPerformanceIndustryNormsvueMethodEnd

    },
    computed: {}
};
</script>
<style>
.uj28m1R {
    margin-bottom: 0.7142857142857143rem;
}

.uH1RPZr {
    width: 100%;
}

.uUIRzUi {
    padding: 2.142857142857143rem 2.857142857142857rem;
}

.uH1RPZr .black-background {
    background-color: #F2F5F9 !important;
    /* 设置背景色为黑色 */
    color: white;
    /* 设置文本颜色为白色以确保可读性 */
    padding-left: 0.7142857142857143rem !important;
    font-weight: bolder !important;
}

.uH1RPZr .black-backgroundone {
    padding-left: 1.4285714285714286rem !important;
}

.uH1RPZr .el-table__row .white-background {
    background-color: #fff !important;
    /* 设置背景色为黑色 */
    font-weight: 200 !important;
}

.uH1RPZr .el-table__row:hover .white-background {
    background-color: #F2F5F9 !important;
    /* 设置背景色为黑色 */
}

.firstColumn {
    display: block;
    width: 35rem;
    background-color: red;
}
</style>